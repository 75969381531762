<template>
  <el-dialog v-if="dataInfo" :title="title" @closed="_closed" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="top5" top="5vh" width="600px">
    <el-form ref="dataInfo" :model="dataInfo" :rules="dataRules" label-width="120px">
      <el-row>
        <el-form-item label="车牌号" prop="carNo">
          <el-input v-model="dataInfo.carNo" autocomplete="off" :disabled="dataInfo.insuranceId"></el-input>
        </el-form-item>
        <el-form-item label="保险类别">
          <el-radio-group v-model="dataInfo.insuranceType" v-if="!dataInfo.insuranceId">
            <el-radio-button label="jqx">交强险</el-radio-button>
            <el-radio-button label="syx">商业险</el-radio-button>
          </el-radio-group>
          <p class="text-bold text-blue padding-left" v-else>{{ dataInfo.insuranceTypeText }}</p>
        </el-form-item>
        <el-form-item label="保单号" prop="orderCode">
          <el-input v-model="dataInfo.orderCode" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="有效期开始" prop="startDate">
          <l-datepicker v-model="dataInfo.startDate"></l-datepicker>
        </el-form-item>
        <el-form-item label="有效期结束" prop="endDate">
          <l-datepicker v-model="dataInfo.endDate"></l-datepicker>
        </el-form-item>
        <el-form-item label="保险公司" prop="companyName">
          <el-input v-model="dataInfo.companyName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="保险保额" prop="coverage">
          <el-input v-model="dataInfo.coverage" type="number" autocomplete="off">
            <span slot="suffix">万元</span>
          </el-input>
        </el-form-item>
        <el-form-item label="保费" prop="fee">
          <el-input v-model="dataInfo.fee" autocomplete="off">
            <span slot="suffix">元</span>
          </el-input>
        </el-form-item>
        <el-form-item label="备注说明" prop="desc">
          <el-input type="textarea" v-model="dataInfo.desc" autocomplete="off"></el-input>
        </el-form-item>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">取 消</el-button>
      <el-button type="primary" @click="save">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      activeName: 'base',
      dataRules: {
        roomName: [{ required: true, trigger: 'blur', message: '会议室名称不能为空' }],
        place: [{ required: true, trigger: 'blur', message: '会议室位置不能为空' }]
      }
    }
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    },
    uploaded(res) {
      Object.assign(this.dataInfo, {
        imgUrlList: res
      })
      // this.$set(this.dataInfo)
    },
    save() {
      this.$refs.dataInfo.validate(async valid => {
        if (valid) {
          await this.$lgh.post('api/enterprise/car/insurance/addoredit', this.dataInfo)
          this.visible = false
          this.onsuccess && this.onsuccess(this.dataInfo)
        }
      })
    }
  }
}
</script>

<style></style>
